import { LearnerLearnerCategory } from 'src/components/Settings/LearnerCategory/LearnerCategoriesCell'

export const getOptions = (filteredCategories: LearnerLearnerCategory[]) => {
  const options = []
  for (const category of filteredCategories) {
    options.push({
      ...category,
      type: 'Category',
    })
    for (const course of category.learnerCourses) {
      options.push({
        ...course,
        type: 'Course',
      })
      for (const activity of course.learnerActivities) {
        options.push({
          id: activity.id,
          courseId: course.id,
          course: course,
          type: 'Chapter',
          name: activity.name,
          __typename: activity.__typename,
        })

        for (const learnerTask of activity.learnerTasks) {
          options.push({
            id: learnerTask.id,
            courseId: course.id,
            course: course,
            type: 'Lesson',
            name: learnerTask.name,
            __typename: learnerTask.__typename,
          })
        }
      }
    }
  }
  return options.sort((a, b) => -b.__typename.localeCompare(a.__typename))
}
