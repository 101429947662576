import { FC, useCallback, useEffect, useState } from 'react'

import { PROD_CLIENT } from 'api/src/common/enums'
import {
  FindLearnerCategories,
  FindLearnerCategoriesVariables,
} from 'types/graphql'

import { CellSuccessProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'
import { useAuth } from 'src/Providers'

import LearnerCategories from '../Categories/LearnerCategories'

export const QUERY = gql`
  query FindLearnerCategories($isStafflinkUser: Boolean! = false) {
    learnerCategories: learnerCategoriesByClientAsAdmin {
      ...LearnerCategoryFields
    }
  }
`

export const beforeQuery = (props) => {
  return {
    variables: props,
    fetchPolicy: 'cache-and-network',
  }
}

export const Loading = () => <LoadingSpinner />

export const Failure = ({ error }) => (
  <div className="rw-cell-error">{error.message}</div>
)

type Props = CellSuccessProps<
  FindLearnerCategories,
  FindLearnerCategoriesVariables
> & {
  isStafflinkUser: boolean
}
export type LearnerLearnerCategory = Props['learnerCategories'][0]
export type LearnerCategoryCourse = LearnerLearnerCategory['learnerCourses'][0]
export type LearnerCategoryRefetch = Props['queryResult']['refetch']

export const Success: FC<Props> = ({
  learnerCategories,
  queryResult: { refetch },
  isStafflinkUser,
}) => {
  const { currentUser } = useAuth()
  const clientId = currentUser.parentData.id

  const safeCategories = useCallback(
    (categories) => {
      if (clientId === PROD_CLIENT.STAFFLINK) {
        return categories.map((category) => ({
          ...category,
          // explode out each learner course and have learnerMilestonesPerClient fallback to an empty array
          learnerCourses: category.learnerCourses.map((course) => ({
            ...course,
            learnerMilestonesPerClient: course.learnerMilestonesPerClient || [],
          })),
        }))
      } else {
        return categories
          .filter((c) => !c.isGlobal)
          .map((category) => {
            return {
              ...category,
              // explode out each learner course and have learnerMilestonesPerClient fallback to an empty array
              learnerCourses: category.learnerCourses.map((course) => {
                return {
                  ...course,
                  learnerMilestonesPerClient:
                    course.learnerMilestonesPerClient || [],
                }
              }),
            }
          })
      }
    },
    [learnerCategories],
  )

  const [learnerCategoriesSafe, setLearnerCategoriesSafe] = useState<
    LearnerLearnerCategory[]
  >(safeCategories(learnerCategories))
  useEffect(() => {
    setLearnerCategoriesSafe(safeCategories(learnerCategories))
  }, [learnerCategories])

  return (
    <LearnerCategories
      categories={learnerCategoriesSafe}
      refetch={refetch}
      isStafflinkUser={isStafflinkUser}
    />
  )
}
