export const CREATE_MEMBERSHIP_MUTATION = gql`
  mutation LearnerCreateCategoryMembershipMutation(
    $input: CreateLearnerCategoryToMembershipGroupInput!
  ) {
    createLearnerCategoryToMembershipGroup(input: $input) {
      id
      learnerCategoryId
      membershipGroupId
    }
  }
`

export const CREATE_CATEGORY_MUTATION = gql`
  mutation CreateLearnerCategoryMutation(
    $input: CreateLearnerCategoryInput!
    $isStafflinkUser: Boolean!
  ) {
    createLearnerCategory(input: $input) {
      ...LearnerCategoryFields
    }
  }
`

export const UPDATE_CATEGORY_MUTATION = gql`
  mutation UpdateLearnerCategoryMutation(
    $id: Int!
    $input: UpdateLearnerCategoryInput!
  ) {
    updateLearnerCategory(id: $id, input: $input) {
      id
      name
      status
      rank
      updatedBy
      isAll
    }
  }
`

export const DELETE_CATEGORY_MUTATION = gql`
  mutation DeleteLearnerCategoryMutation($id: Int!) {
    deleteLearnerCategory(id: $id) {
      id
    }
  }
`

export const DELETE_MEMBERSHIP_MUTATION = gql`
  mutation LearnerDeleteCategoryMembershipMutation($id: Int!) {
    deleteLearnerCategoryToMembershipGroup(id: $id) {
      id
    }
  }
`

export default {
  CREATE_CATEGORY_MUTATION,
  CREATE_MEMBERSHIP_MUTATION,
  UPDATE_CATEGORY_MUTATION,
  DELETE_CATEGORY_MUTATION,
  DELETE_MEMBERSHIP_MUTATION,
}
