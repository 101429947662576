import { GlobalRole } from 'types/graphql'

import { Metadata } from '@redwoodjs/web'

import PageHeader from 'src/components/PageHeader/PageHeader'
import LearnerCategoriesCell from 'src/components/Settings/LearnerCategory/LearnerCategoriesCell'
import { useAuth } from 'web/src/Providers'

const LearnerCategoriesPage = () => {
  const { currentUser } = useAuth()

  const isStafflinkUser = (
    ['STAFFLINK', 'SUPERADMIN'] as GlobalRole[]
  ).includes(currentUser.userData.role)

  return (
    <>
      <Metadata
        title="Settings - Learner - Categories"
        description="Settings - Learner - Categories"
      />
      <PageHeader title="Learner Categories" />
      <LearnerCategoriesCell isStafflinkUser={isStafflinkUser} />
    </>
  )
}

export default LearnerCategoriesPage
