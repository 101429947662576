import type React from 'react'

import AvatarGenerator from 'react-avatar-generator'

interface Props {
  height: number
  width: number
  setImage: any // No types defined in the package for this
}

const colors = [
  '#312e81',
  '#3730a3',
  '#4338ca',
  '#4f46e5',
  '#6366f1',
  '#818cf8',
  '#a5b4fc',
  '#c7d2fe',
  '#e0e7ff',
]

const CoursePlaceholderImage: React.FC<Props> = ({
  height,
  width,
  setImage,
}) => {
  return (
    <>
      <style>
        {`.coursePlaceholder canvas {
          width: ${width}px
          height: ${height}px
        }`}
      </style>
      <div
        className={`h-[${height}px] w-[${width}px] rounded-lg overflow-hidden m-0 p-0 coursePlaceholder`}
      >
        <AvatarGenerator
          height={height - 60}
          width={width - 105}
          opacity={0.7}
          colors={colors}
          backgroundColor={'#bae6fd'}
          ref={(img) => {
            setImage(img)
          }}
        />
      </div>
    </>
  )
}

export default CoursePlaceholderImage
